import moment from 'moment';
import axios from 'axios';
import { config } from './Configurator'

import { executeUpdateCase } from './api/UpdateCase';
import { executeUpdateCaseDataReview } from './api/UpdateCaseDataReview';
import { executeUpdateCaseDataReview_42 } from './api/UpdateCaseDataReview_42';
import { executeUpdateCaseDataReviewFinalVar_43 } from './api/UpdateCaseDataReviewFinalVar_43';
import { executeUpdateCaseDataReviewFinalVar } from './api/UpdateCaseDataReviewFinalVar';
import { executeUpdateCaseLive } from './api/UpdateCaseLive';
import { executeUpdateCaseLiveRiskSub } from './api/UpdateCaseLiveRiskSub';
import { executeMasterUpdateCase } from './api/MasterUpdateCase';
import { executeUpdateCaseStripped } from './api/UpdateCaseStripped';
import { executeUpdateCaseStrippedB2CDeadLead } from './api/UpdateCaseStrippedB2CDeadLead';
import { executeUpdateCaseStrippedDeadLead } from './api/UpdateCaseStrippedDeadLead';
import { executeUpdateCaseReducedSec } from './api/UpdateCaseReducedSec';
import { executeUpdateCaseReducedSecLive } from './api/UpdateCaseReducedSecLive';
import { executeUpdateCaseReducedSecLiveB2B } from './api/UpdateCaseReducedSecLiveB2B';
import { executeUpdateCaseLend } from './api/UpdateCaseLend';
import { executeUpdateCustomer } from './api/UpdateCustomer'
import { executeUpdateCustomerOnlyStatus } from './api/UpdateCustomerOnlyStatus'
import { executeUpdateCustomerUpdateCase } from './api/UpdateCustomerUpdateCase'
import { executeUpdateCustomerUpdateCaseHoT } from './api/UpdateCustomerUpdateCaseHoT'
import { executeUpdateCustomerUpdateCaseReduced } from './api/UpdateCustomerUpdateCaseReduced'
import { executeCreateNewProspectV2 } from './api/CreateNewProspectV2';
import { executeCreateNewProspectV2Lead } from './api/CreateNewProspectV2Lead';
import { executeCreateNewProspectV2Lend } from './api/CreateNewProspectV2Lend';
import { executeCreateCaseJSON } from './api/CreateCaseJSON';
import { executeCreateCaseJSONwithID } from './api/CreateCaseJSONwithID';
import { executeCreateSource } from './api/CreateSource';
import { executeCreateSourceFromLead } from './api/CreateSourceFromLead';
import { executeMasterCreateCaseJSON } from './api/MasterCreateCaseJSON';
import { executeCreateCaseJSONLend } from './api/CreateCaseJSONLend';
import { executeUpdateCustomerLead } from './api/UpdateCustomerLead';
import { executeUpdateCustomerLeadWithCompName } from './api/UpdateCustomerLeadWithCompName';

//export const currency = (m) => {
//    if(isNaN(m) || !m) {
//        return m;
//    }
//    return '£' + m.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//}

export const currency = (amount, decimalCount = 2, decimal = ".", thousands = ",") => 
{

    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    
        const negativeSign = amount < 0 ? "-" : "";
    
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
    
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
        console.log(e)
      }
    
}


export const twoDecimals = (v) => 
{
    v = convertToDouble(v);
    return v.toFixed(2);
}



export const peregrine_calc_wrapper = (att) => {

    let amount = convertToDouble(att.amount_financed);
    let p1 = convertToDouble(att.payment_profile1);
    let p2 = convertToDouble(att.payment_profile2);
    let yld = att.yield; 
    let balloon = convertToDouble(att.balloon);


    return twoDecimals(peregrine_calc(amount, balloon, yld, p2, 0, p1));

}

export const peregrine_calc = (advance, rv, r, n, t, z) => {

    r = r / 100 / 12;
    let a = (advance - (rv*(1+r)**-t)) / (((1-(1+r)**-n)/r) + z);
    return a;
}

export const pmt_calc = (att) => {

    // 01 INPUTS
    // Calculate freq. to years
    let loan_in_years = 0;		// TERM OF LOAN IN YEARS
    let periods_per_year = 0;
    let terms_total = parseInt(att.payment_profile1 + att.payment_profile2);
    if(att.frequency=='Monthly') {loan_in_years = terms_total / 12; periods_per_year = 12; }
    if(att.frequency=='Quarterly') {loan_in_years = terms_total / 4; periods_per_year = 4; }  
    if(att.frequency=='Half Year') {loan_in_years = terms_total / 2; periods_per_year = 2; }
    if(att.frequency=='Yearly') {loan_in_years = terms_total / 1; periods_per_year = 1; }  

    let _d7 = att.yield / 100;		// ANUAL INTEREST RATE (6%=0.06)
    let _d14 = periods_per_year; 	// periods per year
    let _d15 = 12; 		            // <== hard-coded to monthly

    // 02 MATH
    let rate =Math.pow((1+_d7/_d15), (_d15/_d14))-1;    // OK
    let nper = loan_in_years * _d14 ;                   // OK
    let _d6 = convertToDouble(att.net_price);           // OK
    let pmtType = 0;                                    // OK, hard-coded 

    return -pmt(rate,nper,_d6,null,pmtType);
}

export const pmt = (ir, np, pv, fv, type) => {
    /*
    * ir   - interest rate per month
    * np   - number of periods (months)
    * pv   - present value
    * fv   - future value
    * type - when the payments are due:
    *        0: end of the period, e.g. end of month (default)
    *        1: beginning of period
    */
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0)
    return -(pv + fv)/np;

    pvif = Math.pow(1 + ir, np);
    pmt = - ir * (pv * pvif + fv) / (pvif - 1);

    if (type === 1)
    pmt /= (1 + ir);

    return pmt;
}

export const convertStringIfNumeric = (d) => {
    // using this to detect numbers in case assesor payload builderremove commas then try to parseFloat 
    let original = d;

    // if string is empty return original    
    if(!d || d==null) return original;

    // if string is a valid date return original
    if(moment(d, "DD-MM-YYYY", true).isValid()) {
        return original;
    };

    d = d + ''; // cast
    d = d.split(',').join('');
    let potentialFloat = parseFloat(d.toString());
    if(isNaN(potentialFloat)) {
        return original;
    } else {
        return potentialFloat;
    }
}

export const convertDate = (d, format='DD-MM-YYYY') => {
    if(!d) {
        return '';
    }
    // input: /Date(1595890800000)/	
    let unix_timestamp = d.replace('/Date(', '').replace(')/', '');
    let m = moment.unix(unix_timestamp/1000);
    return m.format(format);
}

export const convertDateForApi = (d) => {
    if(!d) return '';
    let r = moment(d, 'D-M-YYYY', true);
    return r.isValid() ? r.format("YYYY-MM-DD") : null;
}

export const convertToDouble = (d) => {
    if(!d || d==null) return 0;
    d = d + ''; // cast
    d = d.split(',').join('');
    let r = parseFloat(d.toString());
    return r;
}

export const getMultipleMainApplicant = (att) => {

    let starts_with = "mc1_MainApp__";
    let ret = { 'MainApp' : null, 'Firstname' : null, 'Surname' : null, 'address1' : null, 'address2' : null, 'address3' : null, 'address4' : null, 'postcode' : null, 'telephone' : null, 'mobile' : null, 'email' : null };
    let main_applicants_found = 0;
    let total_applicants = 0;
    Object.keys(att).forEach(function(key,index) {
        if(key.substring(0,13)===starts_with) {
            let x = key.replace(starts_with, '');
            total_applicants = x;
            if(att[key]=="true") {
                main_applicants_found ++;
                ret['MainApp'] = att['mc1_MainApp__' + x];
                ret['Firstname'] = att['mc1_Firstname__' + x];
                ret['Surname'] = att['mc1_Surname__' + x];
                ret['address1'] = att['mc1_address1__' + x];
                ret['address2'] = att['mc1_address2__' + x];
                ret['address3'] = att['mc1_address3__' + x];
                ret['address4'] = att['mc1_address4__' + x];
                ret['postcode'] = att['mc1_postcode__' + x];
                ret['telephone'] = att['mc1_telephone__' + x];
                ret['mobile'] = att['mc1_mobile__' + x];
                ret['email'] = att['mc1_email__' + x];
            }
        }
    });
    if(total_applicants>0 && main_applicants_found==0) {
        att.mc1_MainApp__0 = 'true';
        return getMultipleMainApplicant(att);
    } else {
        return ret;
    }
}

export const removeMultipleMainApplicants = (att, dropdown_index) => {
    
    let starts_with = "mc1_MainApp__";
    let applicant_indexes_found_to_be_true = [];

    Object.keys(att).forEach(function(key,index) {
        if(key.substring(0,13)===starts_with) {
            if(att[key]=="true") {
                applicant_indexes_found_to_be_true.push(key);
            }
        }
    });

    if(applicant_indexes_found_to_be_true.length==0 || applicant_indexes_found_to_be_true.length==1) {
        return att;
    }
    // if we have more than 1
    // if currently visible element is yes, drop it, otherwise drop the last element and set main applicant to false for the rest
    if(dropdown_index && applicant_indexes_found_to_be_true.includes('mc1_MainApp__' + dropdown_index)) {
        let index = applicant_indexes_found_to_be_true.indexOf('mc1_MainApp__' + dropdown_index);
        if (index !== -1) {
            applicant_indexes_found_to_be_true.splice(index, 1);
        }
    } else {
        applicant_indexes_found_to_be_true.pop();
    }


    for(let i = 0; i<applicant_indexes_found_to_be_true.length; i++) {
        let key = applicant_indexes_found_to_be_true[i];
        att[key]='false';
    }

    return att;
}

export const populateIntroducerType = (data) => {
    // 
}

export const boApiSendProposal = (endpoint, data, formik, applicant_id=null, customer_reference=null) => {


    formik.setSubmitting(false);

    let att = data.attributes; // shorthand
    let r = false;

    if(endpoint === 'UpdateCase') {
        r = executeUpdateCase(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseDataReview') {
        r = executeUpdateCaseDataReview(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseDataReview_42') {
        r = executeUpdateCaseDataReview_42(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseDataReviewFinalVar_43') {
        r = executeUpdateCaseDataReviewFinalVar_43(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseDataReviewFinalVar') {
        r = executeUpdateCaseDataReviewFinalVar(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseLive') {
        r = executeUpdateCaseLive(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseLiveRiskSub') {
        r = executeUpdateCaseLiveRiskSub(att, applicant_id, customer_reference);
    }

    if(endpoint === 'MasterUpdateCase') {
        r = executeMasterUpdateCase(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseStripped') {
        r = executeUpdateCaseStripped(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseStrippedB2CDeadLead') {
        r = executeUpdateCaseStrippedB2CDeadLead(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseStrippedDeadLead') {
        r = executeUpdateCaseStrippedDeadLead(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseReducedSec') {
        r = executeUpdateCaseReducedSec(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseReducedSecLive') {
        r = executeUpdateCaseReducedSecLive(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseReducedSecLiveB2B') {
        r = executeUpdateCaseReducedSecLiveB2B(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseLend') {
        r = executeUpdateCaseLend(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCustomer') {
        r = executeUpdateCustomer(att, applicant_id, customer_reference)
    } 

    if(endpoint === 'UpdateCustomerOnlyStatus') {
        r = executeUpdateCustomerOnlyStatus(att, applicant_id, customer_reference)
    }

    if(endpoint === 'UpdateCustomerLead') {
        r = executeUpdateCustomerLead(att, applicant_id, customer_reference)
    } 

    if(endpoint === 'UpdateCustomerLeadWithCompName') {
        r = executeUpdateCustomerLeadWithCompName(att, applicant_id, customer_reference)
    } 

    if(endpoint === 'UpdateCustomerUpdateCase') {
        r = executeUpdateCustomerUpdateCase(att, applicant_id, customer_reference)
    }

    if(endpoint === 'UpdateCustomerUpdateCaseHoT') {
        r = executeUpdateCustomerUpdateCaseHoT(att, applicant_id, customer_reference)
    }

    if(endpoint === 'UpdateCustomerUpdateCaseReduced') {
        r = executeUpdateCustomerUpdateCaseReduced(att, applicant_id, customer_reference)
    } 

    if(endpoint === 'CreateNewProspectV2') {
        r = executeCreateNewProspectV2(att)
    }

    if(endpoint === 'CreateNewProspectV2Lead') {
        r = executeCreateNewProspectV2Lead(att)
    }

    if(endpoint === 'CreateNewProspectV2Lend') {
        r = executeCreateNewProspectV2Lend(att)
    }

    if(endpoint === 'CreateCaseJSON') {
        r = executeCreateCaseJSON(att, applicant_id, customer_reference)
    }

    if(endpoint === 'CreateCaseJSONwithID') {
        r = executeCreateCaseJSONwithID(att, applicant_id, customer_reference)
    }

    if(endpoint === 'CreateSource') {
        r = executeCreateSource(att, applicant_id, customer_reference)
    }

    if(endpoint === 'CreateSourceFromLead') {
        r = executeCreateSourceFromLead(att, applicant_id, customer_reference)
    }

    if(endpoint === 'MasterCreateCaseJSON') {
        r = executeMasterCreateCaseJSON(att, applicant_id, customer_reference)
    }

    if(endpoint === 'CreateCaseJSONLend') {
        r = executeCreateCaseJSONLend(att, applicant_id, customer_reference)
    }

    return r;

}

export const multiplyFields = (att, d) => {
    let a = [];
    let data = att;

    for (let i=0; i<100; i++) {

        let sufix = "__" + i;

        if(typeof att["mc1_Firstname" + sufix] != 'undefined' && att["mc1_Firstname" + sufix]) {

            if(typeof att["mc1_DateOfBirth" + sufix] != 'undefined') att["mc1_DateOfBirth" + sufix] = convertDateForApi(att["mc1_DateOfBirth" + sufix]);

            let newObject = JSON.parse(JSON.stringify(d)); // deep copy of description object
            let keysFound = 0;
            
            Object.keys(newObject).forEach(e => { 
                let key = e;
                let value = newObject[e];
                
                let dataKey = (value + sufix).replace('att.', ''); 
                let getActualValue = data[dataKey];
                if(typeof getActualValue != 'undefined') {
                    keysFound ++;
                    newObject[key] = getActualValue;
                } else {
                    newObject[key] = null;
                }
            });

            if(keysFound > 0) {
                console.log('keys found, adding object');
                newObject['CustomFields'] = [];

                if(data['mc1_CustomFieldValue1' + sufix]) { newObject['CustomFields'].push({ CustomFieldNo: 1, CustomFieldValue: data['mc1_CustomFieldValue1' + sufix] }) }
                if(data['mc1_CustomFieldValue2' + sufix]) { newObject['CustomFields'].push({ CustomFieldNo: 2, CustomFieldValue: data['mc1_CustomFieldValue2' + sufix] }) }
                if(data['mc1_CustomFieldValue3' + sufix]) { newObject['CustomFields'].push({ CustomFieldNo: 3, CustomFieldValue: data['mc1_CustomFieldValue3' + sufix] }) }
                if(data['mc1_CustomFieldValue4' + sufix]) { newObject['CustomFields'].push({ CustomFieldNo: 4, CustomFieldValue: data['mc1_CustomFieldValue4' + sufix] }) }
                if(data['mc1_CustomFieldValue5' + sufix]) { newObject['CustomFields'].push({ CustomFieldNo: 5, CustomFieldValue: data['mc1_CustomFieldValue5' + sufix] }) }
                if(data['mc1_CustomFieldValue6' + sufix]) { newObject['CustomFields'].push({ CustomFieldNo: 6, CustomFieldValue: data['mc1_CustomFieldValue6' + sufix] }) }
                if(data['mc1_CustomFieldValue7' + sufix]) { newObject['CustomFields'].push({ CustomFieldNo: 7, CustomFieldValue: data['mc1_CustomFieldValue7' + sufix] }) }
                if(data['mc1_CustomFieldValue8' + sufix]) { newObject['CustomFields'].push({ CustomFieldNo: 8, CustomFieldValue: data['mc1_CustomFieldValue8' + sufix] }) }
                if(data['mc1_CustomFieldValue9' + sufix]) { newObject['CustomFields'].push({ CustomFieldNo: 9, CustomFieldValue: data['mc1_CustomFieldValue9' + sufix] }) }

                a.push(newObject);
            } else {
                console.log('keys not found, break');
                break;
            }
        }
    }

    return a;
}

export const orderResult = (objs, field, direction='asc') => {

    if(objs.length==0) {
        console.log("Sorting failed, empty set");
        return objs;
    }
    if(typeof objs[0][field] == 'undefined') {
        console.log("Sorting failed, key not found");
        return objs;
    }
    if(direction==='asc') {
        objs.sort((a, b) => a[field].localeCompare(b[field]));
    } else {
        objs.sort((b, a) => a[field].localeCompare(b[field]));
    }
    return objs;
}

export const searchCustomFieldsForIndex = (customFields, index) => {
    
    if(!Array.isArray(customFields)) {
        return null;
    }

    for(let i=0; i<customFields.length; i++) {
        if(customFields[i].index==index) return customFields[i].CustomFieldValue;
    }

    return null;

}

/**
 * For given object fetch property given as a string (eg. "UserData.Username")
 */

export const  fetchFromObject = (obj, prop) => {

    if(typeof obj === 'undefined') {
        return false;
    }

    var _index = prop.indexOf('.')
    if(_index > -1) {
        return fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1));
    }

    return obj[prop];
}

export const buildCaseAssessorPayload = (att, value, responseData) => {

    let ret = {
        "jobCode": value,
        "multiRecordList": [{"questions": responseData.d.Records}],
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        }
    }
    // modify payload with answers 
    let matches = 0;
    for(let i=0; i<ret.multiRecordList[0].questions.length; i++) {
        let qno = ret.multiRecordList[0].questions[i].Qno;
        if(typeof att['caseAssessor' + qno] !== 'undefined') { 
            matches ++;
            ret.multiRecordList[0].questions[i].Answer = convertStringIfNumeric(att['caseAssessor' + qno]);
        }
    }
    return ret;

}

export const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
}

export const creditSafeDirectorSearchFunction = async(customer_reference, pRequestOverride={}) => 
{
    return new Promise((resolve, reject) => {

        let pRequest_default = {
            "CustomerCode": customer_reference,
            "CustomerAdditionalNumber": "",
            "firstName": "",
            "lastName": "",
            "dateOfBirth": ""
        }

        axios.post(config().base_url + 'creditSafeDirectorSearch', {
            "pRequest": {...pRequest_default, ...pRequestOverride},
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
        })
        .then(response => {
            if(response.data.d && response.data.d.Records) {
                resolve(response.data.d.Records);
            } else {
                reject("Error while getting the results")
            }
        })
        .catch(error => {
            reject('an error occurred');
            console.log(error);
        });
    });
}

export const CreditSafeDirectorCreditReport = async(customer_reference, pRequestOverride={}) =>
{
    return new Promise((resolve, reject) => {

        let pRequest_default = {
            "CustomerCode": customer_reference,
            "CustomerAdditionalNumber": "",
            "PeopleId": ""
        }

        axios.post(config().base_url + 'CreditSafeDirectorCreditReport', {
            "pRequest": {...pRequest_default, ...pRequestOverride},
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
        })
        .then(response => {
            if(response.data.d && response.data.d.Records) {
                resolve(response.data.d.Records);
            } else {
                reject("Error while getting the results")
            }
        })
        .catch(error => {
            reject('an error occurred');
            console.log(error);
        });
    });
}


export const creditSearch = async (customer_reference, context='pdf', pRequestOverride={}) =>
{
    return new Promise((resolve, reject) => {

        let pRequest_default = {
            "CustomerCode": customer_reference,
            "Name": "",
            "Postcode": ""
        }

        axios.post(config().base_url + 'CreditSafeCompanySearch', {
            "pRequest": {...pRequest_default, ...pRequestOverride},
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
        })
        .then(response => {
            if(response.data.d && response.data.d.Records && response.data.d.Records[0].id) {
                if(context=='pdf') {
                    if(response.data.d.Records && response.data.d.Records[0]) {
                        resolve(response.data.d.Records[0].id);
                    } else {
                        resolve(null);
                    }
                }
                if(context=='records') {
                    resolve(response.data.d.Records);
                }
                // this.getPdf();
            } else {
                reject("Error while getting the results")
            }
        })
        .catch(error => {
            reject('an error occurred');
            console.log(error);
        });
    });
        
} 


export const creditQuickSearch = async (search) =>
{

    return new Promise((resolve, reject) => {

        let pRequest_default = {
            "CustomerCode": "",
            "Name": search,
            "Postcode": ""
        }

        axios.post(config().base_url + 'CreditSafeCompanySearch', {
            "pRequest": {...pRequest_default },
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
        })
        .then(response => {
            resolve(response.data.d.Records);
        })
        .catch(error => {
            reject('an error occurred');
            console.log(error);
        });
    });

} 

export const creditSafeAddCompanyToPortfolio = async (customer_reference, connect_id="") => 
{
    return new Promise((resolve, reject) => {

        axios.post(config().base_url + 'CreditSafeAddCompanyToPortfolio', {
            "pRequest": {
                "CustomerCode": customer_reference,
                "ConnectId": connect_id                
            },
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
        })
        .then(response => {
            resolve(response.data.d.Records);
        })
        .catch(error => {
            reject('an error occurred');
            console.log(error);
        });
    });

}

export const getPdf = (connect_id, customer_reference) =>
{

    return new Promise((resolve, reject) => {

        axios.post(config().base_url + 'CreditSafeCompanyCreditReport', {
            "pRequest": {
                "CustomerCode": customer_reference,
                "ConnectId": connect_id
            },
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
        })
        .then(response => {
            let url = (response.data.d.Records[0]);
            var link = document.createElement('a');
            link.href = url;
            link.target = "_blank"
            link.download = 'CreditSafeCompanyCreditReport_' + customer_reference + '.pdf';
            link.dispatchEvent(new MouseEvent('click'));
            resolve(true);
        })
        .catch(error => {
            alert('an error occurred while geting the PDF');
            console.log(error);
            resolve(false);
        });
    

    });
}


export const getCreditSafeBearerToken = () =>
{
    return new Promise((resolve, reject) => {

        let url = "https://connect.creditsafe.com/v1/authenticate";
        let auth_data = {
            "username": "mike.newhouse@rewardcf.com",
            "password": "4OZ4Gl)QT~Z8PV8KXNFRV7"
            };

        axios.post(url, auth_data)
        .then(response => {
            resolve(response.data.token);
        })
        .catch(error => {
            alert('an error occurred');
            console.log(error);
            resolve(false);
        });
    });
}

export const getCreditSafeCurrentDirectorsData = (gb, token) =>
{
    console.log(token);

    return new Promise((resolve, reject) => {

        const config = {
            'Authorization': 'Bearer ' + token,
            'Access-Control-Allow-Origin' : '*',
            'Content-Type': 'application/json', 
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            };

        let url = "https://connect.sandbox.creditsafe.com/v1/companies/" + gb + "?language=en";

        axios.get(url, { params:{}, cancelToken:'', headers:{authorization: 'Bearer ' + token}} )
       .then(response => {
           resolve(response.data.report.directors.currentDirectors);
        })
        .catch(error => {
            alert('an error occurred');
            console.log(error);
            resolve(false);
        });
    });

}

export const getPaginate = (array, page_size, page_number) => 
{
    page_number --;
    return array.slice(page_number * page_size, page_number * page_size + page_size)
}
  
export const getFinancialYearSummary = async() => 
{
    return new Promise((resolve, reject) => {

        axios.post(config().base_url + 'GetFinancialYearSummary', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
        })
        .then(response => {
            if(response.data.d.EnquiryResult.SuccessCode===0) {
                resolve(response.data.d.Records);
            }
            resolve(null);
        })
        .catch(error => {
            resolve(null);
        });
    });
}

export const getAccountsAnalytics = async() => 
{
    return new Promise((resolve, reject) => {

        axios.post(config().base_url + 'GetAccountsAnalytics', {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        })
        .then(response => {
            if(response.data.d.EnquiryResult.SuccessCode===0) {
                resolve(response.data.d.Records);
            }
            resolve(null);
        })
        .catch(error => {
            resolve(null);
        });
    });
}

export const getDataAnalyticsByDateRange = async() => 
{
    return new Promise((resolve, reject) => {

        axios.post(config().base_url + 'GetDataAnalyticsByDateRange', {
            "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
            }, 
            "Options": {
                "ModuleName": "SalesEnquires",
                "Sub_Module": "",
                "DateFrom": "2019-01-01",
                "DateTo": "",
                "Owner": ""
            }
        })
        .then(response => {
            if(response.data.d.EnquiryResult.SuccessCode===0) {
                resolve(response.data.d.Records);
            }
            resolve(null);
        })
        .catch(error => {
            resolve(null);
        });
    });
}

export const getStaff = async(simple_array=false) => 
{
    return new Promise((resolve, reject) => {

        axios.post(config().base_url + 'GetStaff', {
            "AllowAssignmentJobs": "",
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
        })
        .then(response => {
            if(response.data.d.EnquiryResult.SuccessCode===0) {
                let data = response.data.d.Records;
                let ret = [];
                for(let i=0; i<data.length; i++) {
                    if(data[i].EmpName && data[i].EmpCode) {
                        if(simple_array===true) {
                            ret.push(data[i].EmpName);
                        } else {
                            ret.push({
                                "value": data[i].EmpCode,
                                "label": data[i].EmpName
                            });
                        }
                    }

                }
                resolve(ret);
            }            
            resolve(null);
        })
        .catch(error => {
            resolve(null);
        });
    });
}
